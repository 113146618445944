import React, { useState, useEffect } from 'react';
import { User, Briefcase } from 'react-feather';
import {
	Modal,
	Input,
	Label,
	Button,
	ModalHeader,
	ModalBody,
	InputGroup,
	InputGroupText,
} from 'reactstrap';
import axios from 'axios';
import swal from 'sweetalert';
import { GET_TYPES, UPDATE_USERS } from '../../../utilities/Endpoints';
import { ClipLoader } from 'react-spinners';

const EditModal = ({ handleModal, isOpen, user, getUsers, activeToggle }) => {
	const [types, setTypes] = useState([]);
	const [typeID, setTypeID] = useState(user.type_id);
	const [state, setState] = useState({});
	const [days, setDays] = useState({});
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [isExecutive, setIsExecutive] = useState(user.is_executive);

	const getTypes = () => {
		const token = localStorage.getItem('token');
		axios
			.get(GET_TYPES, {
				headers: { user: token },
			})
			.then((response) => {
				setTypeID(state && state.type_id);
				setTypes(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleSubmit = () => {
		const data = {
			joining_date: state.joining_date,
			name: state.name,
			type_id: typeID,
			goal: state.goal,
			report_order: state.order,
			status: Boolean(status),
			is_executive: Boolean(isExecutive),
			mon: Boolean(days.mon),
			tue: Boolean(days.tue),
			wed: Boolean(days.wed),
			thu: Boolean(days.thu),
			fri: Boolean(days.fri),
			sat: Boolean(days.sat),
			sun: Boolean(days.sun),
		};

		setLoading(true);
		console.log(data);
		const token = localStorage.getItem('token');
		axios
			.patch(UPDATE_USERS + user.id, data, {
				headers: { user: token },
			})
			.then((response) => {
				getUsers(activeToggle);
				swal({
					title: 'Success',
					text: 'User Updated',
					icon: 'success',
					button: 'OK',
				});
				handleModal();
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				handleModal();
				swal('Oops!', { err }, 'error');
			});
	};

	const handleChange = (evt) => {
		const value = evt.target.value;

		setState({
			...state,
			[evt.target.name]: value,
		});
	};

	const toggleWorkingDays = (evt) => {
		const value = evt.target.checked;
		setDays({
			...days,
			[evt.target.name]: value,
		});
	};

	const toggleStatus = () => {
		setStatus(!status);
	};

	const toggleExecutive = () => {
		setIsExecutive(!isExecutive);
	};

	useEffect(() => {
		setStatus(user.status);
		getTypes();
		setState({
			joining_date: user && user.joining_date,
			name: user && user.name,
			type_id: user && user.type_id,
			goal: user && user.goal,
			order: user && user.report_order,
		});
		setDays({
			mon: user && user.mon,
			tue: user && user.tue,
			wed: user && user.wed,
			thu: user && user.thu,
			fri: user && user.fri,
			sat: user && user.sat,
			sun: user && user.sun,
		});
	}, []);

	return (
		<>
			<Modal
				isOpen={isOpen}
				toggle={handleModal}
				className='sidebar-sm'
				modalClassName='modal-slide-in'
				contentClassName='pt-0'
			>
				<ModalHeader
					className='mb-1'
					toggle={handleModal}
					close={handleModal}
					tag='div'
				>
					<h5 className='modal-title'>Update User</h5>
				</ModalHeader>
				<ModalBody className='flex-grow-1'>
					<div className='mb-1'>
						<Label className='form-label' for='full-name'>
							Full Name
						</Label>
						<InputGroup>
							<InputGroupText>
								<User size={15} />
							</InputGroupText>
							<Input
								id='full-name'
								name='name'
								value={state && state.name}
								placeholder='Bruce Wayne'
								onChange={(e) => handleChange(e)}
							/>
						</InputGroup>
					</div>
					<div className='mb-1'>
						<Label className='form-label' for='post'>
							Speciality
						</Label>
						<InputGroup>
							<InputGroupText>
								<Briefcase size={15} />
							</InputGroupText>
							<select
								name='speciality'
								id='post'
								className='form-control'
								onChange={(e) => {
									setTypeID(parseInt(e.target.value));
								}}
							>
								{types.map((item) => {
									return (
										<option
											name='type'
											selected={
												state && state.type_id === item.id ? true : false
											}
											value={item.id}
											key={item.id}
										>
											{item.name}
										</option>
									);
								})}
							</select>
						</InputGroup>
					</div>
					<div className='mb-1'>
						<Label className='form-label' for='goal'>
							Goal
						</Label>
						<InputGroup>
							<InputGroupText>
								<User size={15} />
							</InputGroupText>
							<Input
								id='goal'
								name='goal'
								type='number'
								placeholder='1000'
								value={state && state.goal}
								onChange={(e) => {
									handleChange(e);
								}}
							/>
						</InputGroup>
					</div>
					<div className='mb-1'>
						<Label className='form-label' for='goal'>
							Report order
						</Label>
						<InputGroup>
							<InputGroupText>
								<User size={15} />
							</InputGroupText>
							<Input
								id='order'
								name='order'
								type='number'
								placeholder='0 - 10'
								value={state && state.order}
								onChange={(e) => {
									handleChange(e);
								}}
							/>
						</InputGroup>
					</div>
					<div className='mb-1 mt-3 row'>
						<div className='col col-md-6 '>
							<h6>{status ? 'Deactivate' : 'Activate'} User</h6>
							<label className='switch'>
								<input
									name='status'
									type='checkbox'
									checked={status}
									value={status}
									onChange={(e) => {
										toggleStatus();
									}}
								/>
								<span className='slider round'></span>
							</label>
						</div>
						<div className='col col-md-6 '>
							<h6>Executive Report</h6>
							<label className='switch'>
								<input
									name='isExecutive'
									type='checkbox'
									checked={isExecutive}
									value={isExecutive}
									onChange={(e) => {
										toggleExecutive();
									}}
								/>
								<span className='slider round'></span>
							</label>
						</div>
					</div>

					<div className='mb-3 mt-3 row'>
						<div className='col col-md-12'>
							<h6>Working Days</h6>
							<div class='form-check form-check-inline form-switch'>
								<input
									class='form-check-input'
									type='checkbox'
									name='mon'
									checked={days && days.mon}
									onChange={(e) => {
										toggleWorkingDays(e);
									}}
								/>
								<label class='form-check-label' for='inlineCheckbox1'>
									Mon
								</label>
							</div>
							<div class='form-check form-check-inline form-switch'>
								<input
									class='form-check-input'
									type='checkbox'
									id='inlineCheckbox2'
									name='tue'
									checked={days && days.tue}
									onChange={(e) => {
										toggleWorkingDays(e);
									}}
								/>
								<label class='form-check-label' for='inlineCheckbox2'>
									Tue
								</label>
							</div>
							<div class='form-check form-check-inline form-switch'>
								<input
									class='form-check-input'
									type='checkbox'
									id='inlineCheckbox3'
									name='wed'
									checked={days && days.wed}
									onChange={(e) => {
										toggleWorkingDays(e);
									}}
								/>
								<label class='form-check-label' for='inlineCheckbox3'>
									Wed
								</label>
							</div>
							<div class='form-check form-check-inline form-switch'>
								<input
									class='form-check-input'
									type='checkbox'
									id='inlineCheckbox3'
									name='thu'
									checked={days && days.thu}
									onChange={(e) => {
										toggleWorkingDays(e);
									}}
								/>
								<label class='form-check-label' for='inlineCheckbox3'>
									Thu
								</label>
							</div>
							<div class='form-check form-check-inline form-switch'>
								<input
									class='form-check-input'
									type='checkbox'
									id='inlineCheckbox3'
									name='fri'
									checked={days && days.fri}
									onChange={(e) => {
										toggleWorkingDays(e);
									}}
								/>
								<label class='form-check-label' for='inlineCheckbox3'>
									Fri
								</label>
							</div>
							<div class='form-check form-check-inline form-switch'>
								<input
									class='form-check-input'
									type='checkbox'
									id='inlineCheckbox3'
									name='sat'
									checked={days && days.sat}
									onChange={(e) => {
										toggleWorkingDays(e);
									}}
									disabled
								/>
								<label class='form-check-label' for='inlineCheckbox3'>
									Sat
								</label>
							</div>
							<div class='form-check form-check-inline form-switch'>
								<input
									class='form-check-input'
									type='checkbox'
									id='inlineCheckbox3'
									name='sun'
									checked={days && days.sun}
									onChange={(e) => {
										toggleWorkingDays(e);
									}}
									disabled
								/>
								<label class='form-check-label' for='inlineCheckbox3'>
									Sun
								</label>
							</div>
						</div>
					</div>

					{loading && loading === true ? (
						<div style={{ marginLeft: '800px' }}>
							<ClipLoader loading={loading} />
						</div>
					) : (
						<>
							<Button className='me-1' color='primary' onClick={handleSubmit}>
								Submit
							</Button>
							<Button color='secondary' onClick={handleModal} outline>
								Cancel
							</Button>
						</>
					)}
				</ModalBody>
			</Modal>
		</>
	);
};

export default EditModal;

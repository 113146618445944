import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Form from '../../utilities/Forms';
import Logo from '../../assets/images/logo.png';
import '../../assets/scss/auth.scss';
import axios from 'axios';
import { LOGIN } from '../../utilities/Endpoints';
import { SyncLoader } from 'react-spinners';
import '../../assets/dashboard/assets/css/bootstrap-tagsinput.css';

const Login = () => {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState(false);
	const [validate, setValidate] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const history = useHistory();

	const validateLogin = () => {
		let isValid = true;
		let validator = Form.validator({
			email: {
				value: email,
				isRequired: true,
				isEmail: true,
			},
			password: {
				value: password,
				isRequired: true,
				minLength: 6,
			},
		});

		if (validator !== null) {
			setValidate({
				validate: validator.errors,
			});
			isValid = false;
		}
		return isValid;
	};

	const authenticate = (e) => {
		e.preventDefault();
		const validate = validateLogin();
		if (validate) {
			setLoading(true);
			const body = {
				email: email,
				password: password,
			};

			axios
				.post(LOGIN, body)
				.then((result) => {
					const token = result.data.token;
					localStorage.setItem('token', token);
					localStorage.setItem('auth', true);
					localStorage.setItem('name', result.data.name);
					history.push('/');
					setLoading(false);
					setError(false);
				})
				.catch((err) => {
					console.log(err);
					setError(true);
					setLoading(false);
				});
		}
	};

	const togglePassword = (e) => {
		if (showPassword) {
			setShowPassword(false);
		} else {
			setShowPassword(true);
		}
	};

	return (
		<>
			<div className='row1 g-0 auth-wrapper'>
				<div className='col-12 col-md-5 col-lg-6 h-100 auth-background-col'>
					<div className='auth-background-holder'></div>
					<div className='auth-background-mask'></div>
				</div>

				<div className='col-12 col-md-7 col-lg-6 auth-main-col text-center'>
					<div className='auth-company-logo'>
						<img src={Logo} alt='Company Logo' />
					</div>
					<div className='d-flex flex-column align-content-end'>
						<div className='auth-body mx-auto'>
							<p>Login to your account</p>
							<div className='auth-form-container text-start'>
								<form
									className='auth-form'
									method='POST'
									onSubmit={authenticate}
									autoComplete={'off'}>
									<div className='email mb-3'>
										<input
											type='email'
											className={`form-control ${
												validate.validate && validate.validate.email ? '' : ''
											}`}
											id='email'
											name='email'
											value={email}
											placeholder='Email'
											onChange={(e) => {
												setEmail(e.target.value);
											}}
										/>

										<div
											className={`invalid-feedback text-start ${
												validate.validate && validate.validate.email
													? 'd-block'
													: 'd-block'
											}`}>
											{validate.validate && validate.validate.email
												? validate.validate.email[0]
												: ''}
										</div>
									</div>

									<div className='password mb-3'>
										<div className='input-group'>
											<input
												type={showPassword ? 'text' : 'password'}
												className={`form-control ${
													validate.validate && validate.validate.password
														? 'is-invalid '
														: ''
												}`}
												name='password'
												id='password'
												value={password}
												placeholder='Password'
												onChange={(e) => setPassword(e.target.value)}
											/>

											<button
												type='button'
												className='btn btn-outline-primary btn-sm'
												onClick={(e) => togglePassword(e)}>
												<i
													className={
														showPassword ? 'far fa-eye' : 'far fa-eye-slash'
													}></i>{' '}
											</button>

											<div
												className={`invalid-feedback text-start ${
													validate.validate && validate.validate.password
														? 'd-block'
														: 'd-none'
												}`}>
												{validate.validate && validate.validate.password
													? validate.validate.password[0]
													: ''}
											</div>
										</div>

										<div className='extra mt-3 row justify-content-between'>
											<div className='col-6'>
												<div className='form-check'>
													<input
														className='form-check-input'
														type='checkbox'
														id='remember'
														checked={remember}
														onChange={(e) =>
															setRemember(e.currentTarget.checked)
														}
													/>
													<label
														className='form-check-label'
														htmlFor='remember'>
														Remember me
													</label>
												</div>
											</div>
											<div className='col-6'>
												<div className='forgot-password text-end'>
													<Link to='/forgot-password'>Forgot password?</Link>
												</div>
											</div>
										</div>
									</div>
									<div className='text-center'>
										{loading === false ? (
											<button
												type='submit'
												className='btn btn-primary w-100 theme-btn mx-auto'>
												Log In
											</button>
										) : (
											<SyncLoader loading={loading} />
										)}
									</div>
								</form>
								<hr />
								{error && error === true ? (
									<div className='alert alert-danger' role='alert'>
										Invalid Username or Password.
									</div>
								) : null}
								<div className='auth-option text-center pt-2'>
									No Account ? You can contact us
									<a href='mailto:info@vigorant.com' className='text-link'>
										here{' '}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ConfirmAlert = (props) => {
	return (
		<div className='vertically-centered-modal'>
			<Modal
				isOpen={props.isOpen}
				toggle={props.closeAlert}
				className='modal-dialog-centered'>
				<ModalHeader toggle={props.closeAlert}>Confirmation Alert</ModalHeader>
				<ModalBody>Are you sure you want to update the status ?</ModalBody>
				<ModalFooter>
					<Button
						color='danger'
						onClick={(e) => props.deleteAction(e, props.userID, props.status)}>
						Update
					</Button>
					<Button color='primary' onClick={props.closeAlert}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default ConfirmAlert;

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../assets/dashboard/assets/img/header-logo.png';
import '../assets/dashboard/assets/css/app.min.css';
import '../assets/dashboard/assets/css/style.css';
import '../assets/dashboard/assets/css/components.css';
import { Button } from 'reactstrap';
import useScript from '../utilities/useScript';

function Header() {
	const history = useHistory();
	useScript('../assets/js/app.min.js');
	useScript('../assets/js/scripts.js');

	const handleLogout = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('auth');
		history.push('/auth');
	};

	return (
		<>
			<div>
				<div id='app'>
					<div className='main-wrapper main-wrapper-1'>
						<div className='navbar-bg' />
						<nav className='navbar navbar-expand-lg main-navbar'>
							<div className='form-inline mr-auto'>
								<ul className='navbar-nav mr-3'>
									<li>
										<a
											href='#'
											data-toggle='sidebar'
											className='nav-link nav-link-lg collapse-btn'
										>
											<i className='fas fa-bars' />
										</a>
									</li>
								</ul>
							</div>
							<ul className='navbar-nav navbar-right'>
								<Button color='danger' onClick={handleLogout}>
									Logout
								</Button>
							</ul>
						</nav>
						<div className='main-sidebar sidebar-style-2'>
							<aside id='sidebar-wrapper'>
								<div
									className='sidebar-brand'
									style={{ background: '#353c48' }}
								>
									<a href='/'>
										<img alt='logo' src={Logo} className='header-logo' />
									</a>
								</div>
								<ul className='sidebar-menu'>
									<li className='menu-header'>
										<b>Vigorant Data</b>
									</li>
									<li>
										<Link to='/' className='nav-link'>
											<i className='fas fa-plus-circle' />
											<span>Daily Practice</span>
										</Link>
									</li>
									<li>
										<Link to='/team' className='nav-link'>
											<i className='fas fa-users' />
											<span>Team</span>
										</Link>
									</li>
									<li>
										<Link to='/report' className='nav-link'>
											<i className='fas fa-edit' />
											<span>Weekly Report</span>
										</Link>
									</li>
									<li>
										<Link to='/ereport' className='nav-link'>
											<i class='fas fa-stethoscope'></i>
											<span>Executive Report</span>
										</Link>
									</li>
									<li>
										<Link to='/newereport' className='nav-link'>
											<i class='fas fa-stethoscope'></i>
											<span>New Executive Report</span>
										</Link>
									</li>
								</ul>
							</aside>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Header;

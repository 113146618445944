import { useState } from 'react';
import Logo from '../../assets/images/logo.png';
import '../../assets/scss/auth.scss';

const Register = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [validate, setValidate] = useState({});

	return (
		<div className="row1 g-0 auth-wrapper">
			<div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
				<div className="auth-background-holder"></div>
				<div className="auth-background-mask"></div>
			</div>

			<div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
				<div className="auth-company-logo">
					<img src={Logo} alt="logo" />
				</div>
				<div className="d-flex flex-column align-content-end">
					<div className="auth-body mx-auto">
						<p>Create your Account</p>
						<div className="auth-form-container text-start">
							<form
								className="auth-form"
								method="POST"
								onSubmit={{}}
								autoComplete={'off'}
							>
								<div className="name mb-3">
									<input
										type="text"
										className={`form-control ${
											validate.validate && validate.validate.name
												? 'is-invalid '
												: ''
										}`}
										id="name"
										name="name"
										value={name}
										placeholder="Name"
										onChange={(e) => setName(e.target.value)}
									/>

									<div
										className={`invalid-feedback text-start ${
											validate.validate && validate.validate.name
												? 'd-block'
												: 'd-none'
										}`}
									>
										{validate.validate && validate.validate.name
											? validate.validate.name[0]
											: ''}
									</div>
								</div>

								<div className="email mb-3">
									<input
										type="email"
										className={`form-control ${
											validate.validate && validate.validate.email
												? 'is-invalid '
												: ''
										}`}
										id="email"
										name="email"
										value={email}
										placeholder="Email"
										onChange={(e) => setEmail(e.target.value)}
									/>

									<div
										className={`invalid-feedback text-start ${
											validate.validate && validate.validate.email
												? 'd-block'
												: 'd-none'
										}`}
									>
										{validate.validate && validate.validate.email
											? validate.validate.email[0]
											: ''}
									</div>
								</div>

								<div className="password mb-3">
									<div className="input-group">
										<input
											type="text"
											className={`form-control ${
												validate.validate && validate.validate.password
													? 'is-invalid '
													: ''
											}`}
											name="password"
											id="password"
											value={password}
											placeholder="Password"
											onChange={(e) => setPassword(e.target.value)}
										/>

										<button
											type="button"
											className="btn btn-outline-primary btn-sm"
										>
											<i className={'far fa-eye-slash'}></i>{' '}
										</button>

										<div
											className={`invalid-feedback text-start ${
												validate.validate && validate.validate.password
													? 'd-block'
													: 'd-none'
											}`}
										>
											{validate.validate && validate.validate.password
												? validate.validate.password[0]
												: ''}
										</div>
									</div>
								</div>
								<div className="text-center">
									<button
										type="submit"
										className="btn btn-primary w-100 theme-btn mx-auto"
									>
										Register Clinic
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
